<template>
  <div id="link-hero-section">
    <div class="banner-section">
      <video loop muted autoplay playsinline poster="" class="background-video">
        <source src="../../assets/video/herovideo.mp4" type="video/mp4" />
      </video>
      <!-- <div class="banner-img"></div> -->
      <div class="banner-text">
        <h1 class="font-4 color-5 banner-text__title text-uppercase">
          Tomodachi
        </h1>
        <h2 v-if="showOfferText" class="offer-text">
          <span>
            {{ getHeroSectionInfo.offer1 }}
          </span>
          <span>
            {{ getHeroSectionInfo.offer2 }}
          </span>
        </h2>
        <h2 class="font-4 color-3 banner-text__sub-title m-0">
          {{ getHeroSectionInfo.title }}
        </h2>
        <h4 class="font-4 color-3 banner-text__extra">
          {{ getHeroSectionInfo.subTitle }}
        </h4>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#reservationModal"
        >
          <i class="fas fa-book"></i>
          Book Now
        </button>
        <button
          class="btn btn-secondary mt-2"
          @click="scrollTo('#link-menu-section')"
        >
          <i class="fa fa-cutlery" aria-hidden="true"></i>
          Menu
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroImage",
  data: function () {
    return {};
  },
  components: {},
  computed: {
    getHeroSectionInfo: function () {
      return this.$store.getters.currentData.heroSection;
    },
    showOfferText () {
      const today = new Date();
      const nextSunday = new Date();
      nextSunday.setDate(today.getDate() + (6 - today.getDay()));
      return today <= nextSunday;
    }
  },
  methods: {
    scrollTo(seciontId) {
      document.querySelector(seciontId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      if (this.$refs.listnav.classList.contains("show"))
        this.$refs.toggler.click();
    }
  },
};
</script>

<style lang="scss">
.banner-section {
  height: 100vh;
  position: relative;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__title {
    text-shadow: 1px 1px 2px #ff0000;
    font-size: 45px;
    padding: 0.5rem;
    animation: moveLeft 2s ease-out;
    text-align: center;
    @media only screen and (max-width: 800px) {
      font-size: 35px !important;
    }
  }
  &__sub-title {
    text-align: center;
    font-size: 25px;
    padding: 0.5rem;
    font-weight: bolder;
    letter-spacing: 0.2em;
    text-shadow: 1px 1px 2px #a42e0e;
    animation: moveRight 2s ease-out;
    @media only screen and (max-width: 800px) {
      font-size: 20px !important;
    }
  }
  &__extra {
    text-align: center;
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 0.2em;
    text-shadow: 1px 1px 2px #a42e0e;
    animation: moveRight 2s ease-out;
  }
}
.background-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.banner-img {
  background-image: url("../../assets/img/hero-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-right: 20px;
  padding-left: 20px;
  object-position: center;
  height: 100%;
  filter: blur(2px);
}

.btn-primary {
  background-color: #003f5c !important;
  border: none !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
  animation: moveTop 2s ease-out;
  font-size: 25px !important;

  @media only screen and (max-width: 800px) {
    font-size: 20px !important;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: #01202e !important;
    transform: scaleX(1.1) scaleY(1.1);
    border-color: none !important;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }
}
.btn-secondary {
  background-color: #003f5c !important;
  border: none !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
  animation: moveTop 2s ease-out;
  font-size: 15px !important;

  @media only screen and (max-width: 800px) {
    font-size: 20px !important;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: #01202e !important;
    transform: scaleX(1.1) scaleY(1.1);
    border-color: none !important;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }
}

@keyframes moveLeft {
  0% {
    opacity: 0.2;
    transform: translateX(-50px);
  }

  80% {
    opacity: 0.7;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveRight {
  0% {
    opacity: 0.2;
    transform: translateX(50px);
  }

  80% {
    opacity: 0.7;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translate(0px);
  }
}
@keyframes moveTop {
  0% {
    opacity: 0.2;
    transform: translateY(50px);
  }

  80% {
    opacity: 0.7;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0px);
  }
}
.offer-text {
  font-size: 36px;
  font-weight: bold;
  color: red;
  animation: glow 1s infinite;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px red;
  }
  50% {
    text-shadow: 0 0 20px red;
  }
  100% {
    text-shadow: 0 0 5px red;
  }
}
</style>
